import { defineStore } from 'pinia'
import { ref } from 'vue'
import useLithium from '~/composables/http/useLithium'
import { searchItemsExcludingChinaToAsia } from '~/utils'

export const useLithiumSupplyGeneralStore = defineStore('lithiu-supply-general', () => {
  const { getChemicalInfo, getMinedInfo } = useLithium()

  const searchMined = ref()
  const searchChemical = ref()

  const getChemicalSearchData = async () => {
    if (searchChemical.value) return
    const response = await getChemicalInfo()

    const regions = searchItemsExcludingChinaToAsia(response.regions)

    searchChemical.value = {
      ...response,
      regions,
    }
  }

  const getMinedSearchData = async () => {
    if (searchMined.value) return
    const response = await getMinedInfo()

    const regions = searchItemsExcludingChinaToAsia(response.regions)

    searchMined.value = {
      ...response,
      regions,
    }
  }

  return {
    searchMined,
    searchChemical,
    getChemicalSearchData,
    getMinedSearchData,
  }
})
